<template>
    <div>
        <v-card id="editcontentproperties" class="pb-2">
            <v-card-text class='contentform'>
                <!-- <v-row justify="space-between">
                    <v-text-field
                        name="order"
                        v-model="item.order"
                        :label="loc('Order')"
                    ></v-text-field>
                </v-row>     -->
                <v-row justify="space-between">
                    <v-select name="subtype" class='ml-3'
                        v-model="item.subtype" 
                        :label="loc('Block Type') "
                        :items="subtypeselectloc"
                    ></v-select>
                </v-row>
                <template v-if="!item.subtype || item.subtype === 'content' || (options.editableSubtypes && options.editableSubtypes.indexOf(item.subtype) > -1)">
                    <v-row justify="space-between">
                        <v-text-field
                            name="video"
                            v-model="item.video"
                            :label="loc('Video or Image URL (if relevant)')"
                        ></v-text-field>
                        <v-select name="videoplacement" v-model="item.videoplacement" class='ml-3'
                            :label="loc('Media Placement') "
                            :items="[{value:'first',text:'Media on the Left'},{value: 'solo', text: 'Media in the Center'},{value:'last',text:'Media on the Right'}]"
                        ></v-select>
                    </v-row>                   
                    <v-row class="pt-3 pb-3">
                        <h2 >Content</h2>
                    </v-row>  
                    <v-row justify="space-between" class="pb-3"  style="width:100%" id="sharededitor">
                        <SharedEditor :record="item" :handleChange="handleBodyChange" :field="options.field" />
                    </v-row>
                </template>
                <v-divider class="mx-4 mt-3"></v-divider>                         
                <v-row class='actionrow mt-3'>
                    <v-btn small color="primary" dark @click="handleSave()" class="mr-1">{{ loc("Save Changes") }}</v-btn>
                    <v-btn small color="secondary" dark @click="handleCancel('close')" class="mr-1">{{ loc("Cancel") }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="handleRemove" text outlined @click="handleRemove" color="red">{{ loc('Remove Block') }}</v-btn>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card class='geekydetails mt-12 elevation-2 d-none' >
            <pre>{{item}}</pre>
        </v-card>
    </div>
</template>

<script>
import { mapState } from 'vuex';
// import AdminTitleBar from "./Admin_TitleBar";
import SharedEditor from './Shared_Editor';
import { filterId } from '../lib/filter';

export default {
    name: "ContentEditExtended",
    props: {
        // list: { type: Array },
        // editId: { type: String },
        record: { type: Object },
        options: { 
            type: Object, 
            default: () => {return {}}
        }
    },
    components: { SharedEditor },
    data() {
        return {
            item: this.record,
            isnew: this.record._id === 'new',
            context_error: '',
            subtypeSelect: this.options.subtypeSelect || 
                [
                    {text: "Content (default)", value: "content"},
                    {text: "Content Cards", value: "cards"},
                    {text: "Content Accordian", value: "accordian"},
                    {text: "Course List", value: "courselisting"},
                    {text: "Registration", value: "register"},
                    {text: "Survey", value: "survey"},
                ]
        }
    },
    computed: {
        ...mapState(['users', 'localize', 'content']),
        subtypeselectloc: function () {
            let list = [];
            for(let k in this.subtypeSelect){
                list.push({ 
                    value: this.subtypeSelect[k].value,
                    text: this.loc( this.subtypeSelect[k].text )
                })
            }
            return list;
        }
    },
    methods: {
        loc: function (str) {
            return (this.localize.languages[this.users.lang] && this.localize.languages[this.users.lang][str]) || str;
        },   
        handleAction: function(action, params){
            this.$emit("actionhandler", action, params);
        },
        handleDescriptionChange: function(val){
            console.log('handleDescriptionChange', val);
            this.item.description = val; 
        },
        handleDescriptionClose: function(val){

        },
        handleBodyChange: function(val, opts) {
            // console.log('handleBodyChange', val, opts)
            // this.$emit("actionhandler", "change", this.item, val, opts);
            let field = (typeof opts === "string" && ['body', 'body2', 'description']) ? opts : 'body'; 
            this.item[field] = val;
        },
        handleRemove: function(val){
            this.$emit("actionhandler", "remove", this.item, val);
        },
        handleSave: function(val){
            this.$emit("actionhandler", "save", this.item, val);
        },
        handleCancel: function(val){
            this.$emit("actionhandler", 'close', val)
        },
        handleStyle: function(val, a, b){
            // console.log('style field updated', a, b, val);
            this.item.style = val;
        },
        validationError(field, message){
            this[field] = message;
        }

    }
  
}
</script>